import React from 'react'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => {
        form.innerHTML = `
        <div class="message  mb-5 success">
        <div class="container">
        <div class="row pt-5 pb-5">
        <div class="col-md-10">
        <p>
        Je bericht is verstuurd!
        Vereist het een antwoord? We proberen zo snel mogelijk te reageren.
        </p>
        </div>
        </div>
        </div>
        </div>
`
      })
      .catch((error) => {
        form.innerHTML = `
        <div class="message mb-5 alert">
        <div class="container">
        <div class="row pt-5 pb-5">
        <div
        class="col-md-10"
        >
        Fout:
        ${error}
        </div>
        </div>
        </div>
        </div>
        `
      })
  }

  render() {
    return (
      <>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input
            type="hidden"
            name="form-name"
            value="contact"
            aria-label="Hidden"
          />

          <div hidden>
            <label id="bot-field" htmlFor={'bot-field'}>
              Laat dit veld leeg:
              <input
                name="bot-field"
                onChange={this.handleChange}
                aria-labelledby="bot-field"
              />
            </label>
          </div>

          <div className="form-group">
            <label id="label-name" htmlFor={'name'}>
              <span>Naam</span>
            </label>

            <div className="input">
              <input
                className="input"
                type={'text'}
                name={'name'}
                onChange={this.handleChange}
                id={'name'}
                required={true}
                autoComplete={'name'}
                aria-labelledby="label-name"
              />
            </div>
          </div>

          <div className="form-group">
            <label
              id="label-email"
              placeholder="Enter your Email"
              htmlFor={'email'}
            >
              <span>E-mailadres</span>
            </label>

            <div className="input">
              <input
                className="input"
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'email'}
                required={true}
                autoComplete={'email'}
                aria-labelledby="label-email"
              />
            </div>
          </div>

          <div className="form-group">
            <label id="label-message" placeholder="" htmlFor={'message'}>
              <span>Bericht</span>
            </label>

            <div className="input">
              <textarea
                className="textarea"
                name={'message'}
                onChange={this.handleChange}
                id={'message'}
                required={true}
                aria-labelledby="label-message"
              />
            </div>
          </div>
          <button className="btn is-link" type="submit">
            Verstuur bericht
          </button>
        </form>
      </>
    )
  }
}
