import React from 'react'

import Layout from '@/components/Layout'
import Form from '@/components/Form'
import LeafletMap from '@/components/Map'

import { useSiteMetaData } from '../hooks'

const ContactPage = () => {
  const siteMetadata = useSiteMetaData()

  return (

    <Layout
      pageTitle="Contact"
      heroTitle="Contact"
      heroSubTitle=""
      heroTagLine=""
      heroImage=""
      metaDescription="Vragen of interesse? Bel, schrijf, mail of kom gewoon langs!"
    >

      <section className="section  pt-5 pt-md-0">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-4 text-center">
              <div className="row justify-content-center pb-2">
                <div className="col col-lg-12 mb-2">
                  <h2 className="h4 mb-2">Dojo</h2>
                  <address>
                    <a
                      href="https://goo.gl/maps/wzuPsVhdFkLTDEo28"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {siteMetadata.streetNr}
                      <br />
                      {siteMetadata.postalcodeCity}
                    </a>
                  </address>
                </div>
                <div className="col col-lg-12 mb-2">
                  <h2 className="h4 mb-2">GSM</h2>
                  <a
                    href={`tel:${siteMetadata.phoneCC}`}
                    rel="noreferrer noopener"
                  >
                    {siteMetadata.phone}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-8 mt-4 mt-md-0">
              <div className="p-2 p-md-3 p-lg-5">
                <div className="pt-0">
                  <Form />
                </div>
              </div>
            </div>
          </div>

          <div
            id="map"
            style={{ position: 'relative', overflow: 'hidden' }}
          >
            <LeafletMap
              position={[50.94466, 4.04611]}
              zoom={14}
              siteMetadata={siteMetadata}
            />
          </div>
        </div>
      </section>
    </Layout>


  )
}

export default ContactPage
